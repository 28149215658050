export type UserAgentBrowsers =
  | 'Unknown'
  | 'Google Chrome'
  | 'Safari'
  | 'Safari WebView'
  | 'Samsung Internet'
  | 'Android WebView'

export type UserAgentOS = 'Unknown' | 'iOS' | 'Android' | 'Windows' | 'MacOS'
export interface ParseUserAgentReturn {
  browser: UserAgentBrowsers
  os: UserAgentOS
}

export function parseUserAgent(): ParseUserAgentReturn {
  const detectedAgent: ParseUserAgentReturn = {
    os: 'Unknown',
    browser: 'Unknown',
  }

  if (!globalThis.navigator) return detectedAgent

  const { userAgent } = globalThis.navigator

  switch (true) {
    case /iPhone|iPad|iPod/.test(userAgent):
      detectedAgent.os = 'iOS'
      if (/CriOS/.test(userAgent)) {
        detectedAgent.browser = 'Google Chrome'
      } else if (/Safari/.test(userAgent)) {
        detectedAgent.browser =
          /AppleWebKit/.test(userAgent) && !/CriOS/.test(userAgent)
            ? 'Safari WebView'
            : 'Safari'
      }
      break

    case /Android/.test(userAgent):
      detectedAgent.os = 'Android'
      if (/SamsungBrowser/.test(userAgent)) {
        detectedAgent.browser = 'Samsung Internet'
      } else if (/Chrome/.test(userAgent)) {
        detectedAgent.browser = 'Google Chrome'
      } else if (/wv/.test(userAgent)) {
        detectedAgent.browser = 'Android WebView'
      }
      break

    case /Windows/.test(userAgent):
      detectedAgent.os = 'Windows'
      if (/Chrome/.test(userAgent)) {
        detectedAgent.browser = 'Google Chrome'
      }
      break

    case /Macintosh/.test(userAgent):
      detectedAgent.os = 'MacOS'
      if (/Chrome/.test(userAgent)) {
        detectedAgent.browser = 'Google Chrome'
      } else if (/Safari/.test(userAgent)) {
        detectedAgent.browser = 'Safari'
      }
      break
  }

  return detectedAgent
}

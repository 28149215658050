import { useLocalStorage } from '@st/use/composables'

/**
 * TODO: вынести все хуки и логику PWA в отдельный пакет/entity
 */
export function usePwaWidgetVisibility() {
  const isWidgetOpen = useLocalStorage('pwaWidgetOpen', true)
  const { $pwa } = useNuxtApp()
  const isPwaInstalled = computed(() => !!($pwa as any)?.isPWAInstalled)

  const isVisiblePwaWidget = computed(
    () => isWidgetOpen.value && !isPwaInstalled.value,
  )

  function closeWidget() {
    isWidgetOpen.value = false
  }

  return {
    isVisiblePwaWidget,
    isPwaInstalled,
    closeWidget,
  }
}

import {
  parseUserAgent,
  type ParseUserAgentReturn,
  type UserAgentOS,
  type UserAgentBrowsers,
} from '@st/utils'

export function usePwaInstall() {
  type actionType = 'install' | 'modal' | 'landing'

  const { os, browser }: ParseUserAgentReturn = parseUserAgent()
  const { $pwa } = useNuxtApp()
  const router = useRouter()

  const pwaConfig: Record<
    UserAgentOS,
    Record<UserAgentBrowsers | 'default', actionType>
  > = {
    Windows: {
      'Google Chrome': 'install',
      Safari: 'landing',
      'Safari WebView': 'landing',
      'Samsung Internet': 'landing',
      'Android WebView': 'landing',
      Unknown: 'landing',
      default: 'landing',
    },
    MacOS: {
      'Google Chrome': 'install',
      Safari: 'modal',
      'Safari WebView': 'modal',
      'Samsung Internet': 'landing',
      'Android WebView': 'landing',
      Unknown: 'landing',
      default: 'landing',
    },
    Android: {
      'Google Chrome': 'modal',
      'Samsung Internet': 'modal',
      Safari: 'landing',
      'Safari WebView': 'landing',
      'Android WebView': 'landing',
      Unknown: 'landing',
      default: 'landing',
    },
    iOS: {
      'Google Chrome': 'modal',
      Safari: 'landing',
      'Safari WebView': 'landing',
      'Samsung Internet': 'landing',
      'Android WebView': 'landing',
      Unknown: 'landing',
      default: 'landing',
    },
    Unknown: {
      'Google Chrome': 'landing',
      Safari: 'landing',
      'Safari WebView': 'landing',
      'Samsung Internet': 'landing',
      'Android WebView': 'landing',
      Unknown: 'landing',
      default: 'landing',
    },
  }

  const modalQuery: Record<UserAgentOS, { modal: string; browser: string }> = {
    MacOS: { modal: 'pwaInstall', browser: 'safari' },
    Android: { modal: 'pwaInstall', browser: 'samsung-internet' },
    iOS: { modal: 'pwaInstall', browser: 'google-chrome' },
    Windows: { modal: 'pwaInstall', browser: 'google-chrome' },
    Unknown: { modal: 'pwaInstall', browser: 'unknown' },
  }

  async function installPwa(): Promise<void> {
    const osConfig = pwaConfig[os] ?? pwaConfig.Unknown
    const action: actionType = osConfig[browser] ?? osConfig.default

    switch (action) {
      case 'install':
        await $pwa?.install()
        break
      case 'modal':
        router.replace({
          query: modalQuery[os] ?? {},
        })
        break
      default:
        router.push('/pwa')
        break
    }
  }

  return {
    installPwa,
  }
}
